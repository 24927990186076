/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { config } from './config';

/**
 * Configuration object to be passed to MSAL instance on creation:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
    auth: {
        clientId: config.AZUREAD_CLIENT_ID + '',
        authority: config.AZUREAD_LOGIN_URL + config.AZUREAD_AUTHORITY_ID,
        redirectUri: config.AZUREAD_REDIRECT_URL,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const publicClientApplication = new PublicClientApplication(msalConfig);

export const loginRequest = {
    scopes: [],
};
