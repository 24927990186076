import { publicClientApplication } from 'src/app/constants/config/authConfig';
import { hasOneOfRoles } from 'src/app/utilities/helpers/userRole';
import { Roles } from 'src/data/enums/roles';

// A function to get the current roles dynamically
const getRoles = (): Roles[] => {
    return (publicClientApplication.getActiveAccount()?.idTokenClaims?.roles || []) as Roles[];
};

// Define your permission checks using the `getRoles` function
const isAdmin = () => hasOneOfRoles([Roles.ADMINISTRATOR], getRoles());

export const nameUpdateRoles = [Roles.ADMINISTRATOR];
const canUpdateEventName = () => hasOneOfRoles(nameUpdateRoles, getRoles());

export const organizerUpdateRoles = [Roles.PRODUCTLEAD, Roles.ADMINISTRATOR];
const canUpdateEventOrganizer = () => hasOneOfRoles(organizerUpdateRoles, getRoles());

export const seriesUpdateRoles = [Roles.PRODUCTLEAD, Roles.ADMINISTRATOR];
const canUpdateEventSeries = () => hasOneOfRoles(seriesUpdateRoles, getRoles());

export const eventCategoryUpdateRoles = [Roles.ADMINISTRATOR];
const canUpdateEventCategory = () => hasOneOfRoles(eventCategoryUpdateRoles, getRoles());

export const DeleteIteneraryFileRoles = [Roles.ADMINISTRATOR];
const canDeleteItieneraryFile = () => hasOneOfRoles(DeleteIteneraryFileRoles, getRoles());

export const ticketLockStatusModifyRoles = [
    Roles.OPERATIONS_LEAD,
    Roles.OPERATIONS_SENIOR,
    Roles.ADMINISTRATOR,
];
const canModifyTicketLockedStatus = () => hasOneOfRoles(ticketLockStatusModifyRoles, getRoles());

export const changePurchaseRoles = [Roles.ADMINISTRATOR, Roles.PRODUCTLEAD, Roles.PRODUCT_SENIOR];
const canChangePurchasePrices = () => hasOneOfRoles(changePurchaseRoles, getRoles());

export const FinanceRoles = [
    Roles.FINANCE,
    Roles.FINANCE_INTERN,
    Roles.FINANCE_JUNIOR,
    Roles.FINANCE_LEAD,
    Roles.FINANCE_SENIOR,
];

export const isFinanceRole = () => hasOneOfRoles(FinanceRoles, getRoles());
export const canLockEvent = () =>
    isFinanceRole() ||
    hasOneOfRoles(
        [Roles.OPERATIONS_LEAD, Roles.OPERATIONS_SENIOR, Roles.PRODUCTLEAD, Roles.PRODUCT_SENIOR],
        getRoles()
    ) ||
    isAdmin();
export const canUnlockEvent = () => isFinanceRole() || isAdmin();
export const canCloseEvent = () => isFinanceRole() || isAdmin();

export default {
    canUpdateEventName,
    canUpdateEventOrganizer,
    canUpdateEventSeries,
    canModifyTicketLockedStatus,
    canChangePurchasePrices,
    canUpdateEventCategory,
    canDeleteItieneraryFile,
};
