import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';
import { useConfirm } from 'material-ui-confirm';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { SeatingplanCategoryItem } from 'src/app/components/previews/venues/seating-plan-categories-preview';
import useDeleteItieneraryFile from 'src/app/hooks/seating-plan-categories/useDeleteItieneraryFile';
import useDeleteSeatingplanCategory from 'src/app/hooks/seating-plan-categories/useDeleteSeatingplanCategory';
import { FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY } from 'src/app/hooks/seating-plan-categories/useFetchSPCsBySeatingplanId';
import useEditSPCItineraryFile from 'src/app/hooks/useEditSPCItineraryFile';
import useUploadSPCImage from 'src/app/hooks/useUploadSPCImage';
import useUploadSPCItineraryFile from 'src/app/hooks/useUploadSPCItineraryFile';
import { generateUid } from 'src/app/utilities/helpers/uid';
import UserPermissions from 'src/app/utilities/helpers/userPermissions';
import Chip from 'src/view/components/chip/Chip';
import PreviewItem from 'src/view/components/preview/PreviewItem';
import { PreviewLabel } from 'src/view/components/preview/PreviewLabel';
import { WarningText } from 'src/view/components/warning-text/WarningText';
import { CreateEditSeatingPlanCategoryModal } from '../../features/venues/CreateSeatingPlanCategoryModal';

/** TODO: Remove the usage of makeStyles */
const useStyles = makeStyles((theme) => ({
    previewItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    previewItemLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    separation: {
        width: 2,
        height: 20,
        background: theme.colors.grey,
        marginRight: theme.spacing(1),
    },
    editButton: {
        marginLeft: theme.spacing(1),
    },
    leftContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            height: 40,
            width: 'auto',
        },
    },
    labelsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
    },
    tagContainer: {
        '& .MuiChip-root': {
            height: 20,
        },
        '& .MuiChip-label': {
            fontSize: '.6rem',
        },
    },
    image: {
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(50%)',
            transition: 'filter .2s ease',
        },
    },
    fileInput: {
        display: 'none',
    },
    imageContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 60,
        justifyContent: 'center',
        '& img': {
            width: '100%',
        },
    },
    imageLoadingOverlay: {
        height: 40,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
    },
    pdfList: {
        paddingBottom: 0,
        flex: 1,
    },
    pdfListItem: {
        padding: 0,
    },
    pdfListItemText: {
        '& span': {
            fontSize: '.675rem',
        },
        '& svg': {
            height: 10,
        },
        '& a': {
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    fileActionBtn: {
        fontSize: '.675rem',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    deletePfd: {
        color: theme.palette.error.main,
    },
    editPfd: {
        color: theme.palette.warning.main,
        marginRight: theme.spacing(1),
    },
    ticketTypes: {
        marginRight: theme.spacing(1),
        fontSize: '.875rem',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    pdfLoading: {
        marginLeft: theme.spacing(2),
    },
    archivedText: {
        color: theme.palette.error.main,
        fontStyle: 'italic',
        marginLeft: theme.spacing(1),
        fontSize: '.75rem',
    },
}));

interface VenueSeatingplanCategoriesPreviewItemProps {
    spcItem: SeatingplanCategoryItem;
    seatingPlanId: string;
    index: number;
}

export const VenueSeatingplanCategoriesPreviewItem = ({
    spcItem,
    seatingPlanId,
    index,
}: VenueSeatingplanCategoriesPreviewItemProps) => {
    const classes = useStyles();
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const [filesUpdatedOn, setFilesUpdatedOn] = useState<Map<string, number>>(new Map());

    const onActionSuccessCallback = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY],
            exact: false,
        });
    }, [queryClient]);

    const [currentSeatingplanCategory, setCurrentSeatingplanCategory] = useState<{
        id: string;
        name: string;
        isArchived: boolean;
    }>();

    const { mutate: deleteSeatingPlanCategory } = useDeleteSeatingplanCategory();

    const imageInputRefs = useRef<Record<string, HTMLInputElement | null>>({});
    const pdfInputRefs = useRef<Record<string, HTMLInputElement | null>>({});
    const pdfEditInputRefs = useRef<
        Map<
            string,
            {
                input: HTMLInputElement | null;
                loading: boolean;
            }
        >
    >(new Map());

    const [deleteingFiles, setDeletingFiles] = useState<Record<string, boolean>>({});

    const { mutate: uploadSPCImage, isLoading: isUploadingSPCImage } = useUploadSPCImage(
        spcItem.id,
        onActionSuccessCallback
    );

    const { mutate: uploadSPCItineraryFile, isLoading: isUploadSPCItineraryFile } =
        useUploadSPCItineraryFile(spcItem.id, onActionSuccessCallback);

    const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files?.[0];

        if (!file) return;

        uploadSPCImage(file);

        const currentRef = imageInputRefs.current[id];
        if (currentRef) {
            currentRef.value = '';
        }
    };

    const handlePdfChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files?.[0];

        if (!file) return;

        uploadSPCItineraryFile(file);

        const currentPdfRef = pdfInputRefs.current[id];
        if (currentPdfRef) {
            currentPdfRef.value = '';
        }
    };

    const { mutate: deleteItineraryFile } = useDeleteItieneraryFile((_, vars) => {
        setDeletingFiles((obj) => ({
            ...obj,
            [vars.fileId]: true,
        }));
        onActionSuccessCallback();
    });

    const { mutate: editItineraryFile } = useEditSPCItineraryFile(spcItem.id, (_, vars) => {
        onActionSuccessCallback();
        pdfEditInputRefs.current.set(vars.fileId, {
            input: pdfEditInputRefs.current.get(vars.fileId)?.input || null,
            loading: false,
        });
        setFilesUpdatedOn((m) => {
            const map = new Map(m);
            map.set(vars.fileId, new Date().getTime());
            return map;
        });
    });

    const handleEditPdfFileChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, fileId: string) => {
            const file = e.target.files?.[0];
            if (!file) return;
            pdfEditInputRefs.current.set(fileId, {
                loading: true,
                input: pdfEditInputRefs.current.get(fileId)?.input || null,
            });
            editItineraryFile({ fileId, file });
        },
        [editItineraryFile]
    );

    return (
        <>
            <PreviewItem id={spcItem.id} index={index} className={classes.previewItem}>
                <div className={classes.previewItemLabel}>
                    <div className={classes.leftContainer}>
                        <div className={classes.imageContainer}>
                            <img
                                className={classes.image}
                                src={spcItem.image?.url || 'https://via.placeholder.com/150'}
                                style={{ objectFit: 'contain' }}
                                onClick={() => {
                                    if (!imageInputRefs?.current) return;

                                    imageInputRefs.current[spcItem.id]?.click();
                                }}
                            />
                            <input
                                type="file"
                                accept=".png, .jpg, .svg"
                                onChange={(e) => handleImgChange(e, spcItem.id)}
                                ref={(el) => (imageInputRefs.current[spcItem.id] = el)}
                                className={classes.fileInput}
                            />
                            {isUploadingSPCImage && (
                                <div className={classes.imageLoadingOverlay}>
                                    <CircularProgress size={20} />
                                </div>
                            )}
                        </div>
                        <div className={classes.labelsContainer}>
                            <div className={classes.tagContainer}>
                                <PreviewLabel
                                    label={
                                        <span>
                                            {spcItem.name}
                                            {spcItem.isArchived ? (
                                                <WarningText text="archived" />
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    }
                                />
                                {spcItem.tags?.length > 0 && (
                                    <Grid container spacing={1}>
                                        {spcItem.tags.map((tag) => (
                                            <Grid item key={`tag-${generateUid()}`}>
                                                <Chip label={tag.name} size="small" />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </div>
                        </div>
                    </div>
                    <Tooltip title={spcItem.ticketTypes.join(', ')}>
                        <span className={classes.ticketTypes}>
                            {spcItem.ticketTypes.length} ticket type(s)
                        </span>
                    </Tooltip>
                    {isUploadSPCItineraryFile ? (
                        <div className={classes.pdfLoading}>
                            <CircularProgress size={16} />
                        </div>
                    ) : (
                        <IconButton
                            onClick={() => {
                                if (!pdfInputRefs?.current) return;

                                pdfInputRefs.current[spcItem.id]?.click();
                            }}
                            className={classes.editButton}
                            color="primary"
                        >
                            <PictureAsPdfIcon />
                        </IconButton>
                    )}
                    <input
                        type="file"
                        accept=".pdf"
                        onChange={(e) => handlePdfChange(e, spcItem.id)}
                        ref={(el) => (pdfInputRefs.current[spcItem.id] = el)}
                        className={classes.fileInput}
                    />

                    <IconButton
                        onClick={() => {
                            setCurrentSeatingplanCategory({
                                id: spcItem.id,
                                name: spcItem.name,
                                isArchived: spcItem.isArchived,
                            });
                        }}
                        className={classes.editButton}
                        color="primary"
                    >
                        <EditIcon />
                    </IconButton>

                    <IconButton
                        onClick={async () => {
                            try {
                                await confirm({
                                    title: 'Are you sure you want to delete this seating plan category?',
                                    description: "This action can't be undone.",
                                });

                                deleteSeatingPlanCategory(spcItem.id);
                            } catch (error) {
                                /* Empty */
                            }
                        }}
                        className={classes.editButton}
                        color="error"
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
                {spcItem.itineraryFiles && spcItem.itineraryFiles.length > 0 && (
                    <List dense className={classes.pdfList}>
                        {spcItem.itineraryFiles.map((file) => (
                            <Fragment key={file.id}>
                                <ListItem
                                    secondaryAction={
                                        <div>
                                            <span
                                                className={`${classes.fileActionBtn} ${classes.editPfd}`}
                                                onClick={() => {
                                                    pdfEditInputRefs.current
                                                        .get(file.id)
                                                        ?.input?.click();
                                                }}
                                            >
                                                {pdfEditInputRefs.current.get(file.id)?.loading ? (
                                                    <CircularProgress size={11} />
                                                ) : (
                                                    'Replace File'
                                                )}
                                            </span>
                                            {UserPermissions.canDeleteItieneraryFile() && (
                                                <span
                                                    className={`${classes.fileActionBtn} ${classes.deletePfd}`}
                                                    onClick={async () => {
                                                        try {
                                                            await confirm({
                                                                title: 'Are you sure you want to delete an itinerary file?',
                                                                description:
                                                                    'If an itinerary file is deleted, the file may no longer be downloadable for the customer. This action has serious consequences, and is not reversible.',
                                                            });

                                                            setDeletingFiles((obj) => ({
                                                                ...obj,
                                                                [file.id]: false,
                                                            }));

                                                            deleteItineraryFile({
                                                                seatingplanCategoryId: spcItem.id,
                                                                fileId: file.id,
                                                            });
                                                        } catch (error) {
                                                            /* Empty */
                                                        }
                                                    }}
                                                >
                                                    {deleteingFiles[file.id] === true ? (
                                                        <CircularProgress size={11} />
                                                    ) : (
                                                        'Delete File'
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    }
                                    disablePadding
                                    className={classes.pdfListItem}
                                >
                                    <ListItemText
                                        className={classes.pdfListItemText}
                                        primary={
                                            <a
                                                href={`${file.url}?lastUpdate=${
                                                    filesUpdatedOn.get(file.id) ?? ''
                                                }`}
                                                target="_blank"
                                            >
                                                {file.name} <OpenInNewIcon />
                                            </a>
                                        }
                                    />
                                </ListItem>
                                <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => handleEditPdfFileChange(e, file.id)}
                                    ref={(el) => {
                                        pdfEditInputRefs.current.set(file.id, {
                                            input: el,
                                            loading:
                                                pdfEditInputRefs.current.get(file.id)?.loading ??
                                                false,
                                        });
                                    }}
                                    className={classes.fileInput}
                                />
                            </Fragment>
                        ))}
                    </List>
                )}
            </PreviewItem>

            {currentSeatingplanCategory && (
                <CreateEditSeatingPlanCategoryModal
                    seatingplanId={seatingPlanId}
                    seatingPlanCategory={currentSeatingplanCategory}
                    setModalOpenState={() => setCurrentSeatingplanCategory(undefined)}
                />
            )}
        </>
    );
};
