import { MsalProvider } from '@azure/msal-react';
import '@fortawesome/fontawesome-free/css/all.css';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import App from 'src/app/App';
import { config } from 'src/app/constants/config/config';
import CustomRouter from 'src/app/routing/CustomRouter';
import history from 'src/app/routing/history';
import { THEME_ENVS, getThemeByName } from 'src/app/theme/theme';
import * as serviceWorker from 'src/serviceWorker';
import { publicClientApplication } from './app/constants/config/authConfig';
import './index.css';

publicClientApplication.initialize();

if (config.NODE_ENV !== 'development' && config.NODE_ENV !== '$REACT_APP_NODE_ENV') {
    Sentry.init({
        dsn: config.REACT_APP_ERROR_SENTRY_DSN,
        environment: config.NODE_ENV,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
const queryClient = new QueryClient();

const getTheme = () => {
    if (config.THEME_TYPE === THEME_ENVS.p1) return getThemeByName(THEME_ENVS.p1);

    return getThemeByName(THEME_ENVS.etbaas);
};

root.render(
    <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={getTheme()}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    autoHideDuration={config.SNACKBAR_FADING_TIME}
                >
                    <CustomRouter history={history}>
                        <MsalProvider instance={publicClientApplication}>
                            <App />
                        </MsalProvider>
                    </CustomRouter>
                </SnackbarProvider>
            </ThemeProvider>
            {config.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
