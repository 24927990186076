import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Grid, IconButton, Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import eventUrlService from 'src/app/pages/events/eventUrlService';
import {
    canCloseEvent,
    canLockEvent,
    canUnlockEvent,
} from 'src/app/utilities/helpers/userPermissions';
import { Event, SeatingPlanCategoryResultsSummary } from 'src/data/models/events/event';
import { dateFormat } from 'src/shared/date';
import { BUTTON_SIZES } from 'src/shared/theme/enums';
import Button from 'src/view/components/button/Button';
import Popover from 'src/view/components/popover/Popover';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_WIDTH,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';

export interface EventsOverviewTableProps {
    includeResultsSummary?: boolean;
    includeSeatingPlansFromSeatingPlanId?: string;
    events: Event[];
    showSeatingPlanCategories?: boolean;
    seatingPlanCategories?: {
        id: string;
        name: string;
        isArchived?: boolean;
    }[];
    onChangeSorting?: (sortings: TableColumnSorting[]) => void;
    onClickDetails: (eventId: string) => void;
    onEventActionDispatched?: (action: 'lock' | 'unlock' | 'close', eventId: string) => void;
    loading?: boolean;
    pagination?: TablePaginationData;
    enableCheckboxes?: boolean;
    includeArchivedSeatingplanCategories?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    eventLink: {
        'font-weight': theme.typography.fontWeightBold,
        color: theme.typography.body1.color,
        '&:link, &:visited, &:active': {
            color: theme.palette.tertiary,
            textDecoration: 'none',
        },
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    columnHeaderSubTitle: {
        color: theme.colors.grey,
        'font-weight': theme.typography.fontWeightBold,
    },
    columnHeaderArchived: {
        color: theme.colors.grey,
        marginBottom: 0,
        paddingBottom: 0,
    },
    columnHeaderArchivedText: {
        color: theme.palette.error.dark,
        fontSize: '0.8rem',
        margin: 0,
        padding: 0,
        textTransform: 'initial',
    },
    statistic: {
        color: theme.palette.text.primary,
        '&:link, &:hover, &:active, &:visited': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
        },
    },
    zeroStatistic: {
        color: theme.colors.grey,
        '&:link, &:hover, &:active, &:visited': {
            color: theme.colors.grey,
            textDecoration: 'none',
        },
    },
    negativeStatistic: {
        color: theme.palette.warning.main,
    },
    zeroTicketSalesCap: {
        '&:link, &:hover, &:active, &:visited': {
            color: theme.palette.warning.main,
            textDecoration: 'none',
        },
        color: theme.palette.warning.main,
    },
    actionButtons: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
    },
}));

export default function EventsOverviewTable({
    includeResultsSummary,
    includeSeatingPlansFromSeatingPlanId,
    onChangeSorting,
    loading,
    events = [],
    showSeatingPlanCategories = false,
    seatingPlanCategories,
    pagination,
    enableCheckboxes,
    onClickDetails,
    onEventActionDispatched,
    includeArchivedSeatingplanCategories,
}: EventsOverviewTableProps): JSX.Element {
    const classes = useStyles();
    const [columns, setColumns] = React.useState<NewTableColumn<Event>[]>([]);

    const findSeatingPlanCategory = (
        seatingPlanCategoryId: string,
        resultSeatingPlans?: SeatingPlanCategoryResultsSummary[]
    ) => {
        if (!resultSeatingPlans) return;

        return resultSeatingPlans.find((s) => {
            return s.id === seatingPlanCategoryId;
        });
    };

    const seatingCategories = useMemo(() => {
        if (includeArchivedSeatingplanCategories) {
            return seatingPlanCategories?.sort((_, b) => (b.isArchived ? -1 : 0));
        }

        return seatingPlanCategories?.filter((category) => !category.isArchived);
    }, [seatingPlanCategories, includeArchivedSeatingplanCategories]);

    useEffect(() => {
        let cols: NewTableColumn<Event>[] = [];
        const actionCols: NewTableColumn<Event>[] = [];

        if (showSeatingPlanCategories && seatingCategories) {
            cols = seatingCategories.map((s) => ({
                title: (
                    <Grid container>
                        <Grid
                            item
                            xs={4}
                            className={classNames(
                                classes.textAlignCenter,
                                classes.columnHeaderSubTitle
                            )}
                        >
                            Tickets
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={classNames(
                                classes.textAlignCenter,
                                classes.columnHeaderSubTitle
                            )}
                        >
                            Sales
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={classNames(
                                classes.textAlignCenter,
                                classes.columnHeaderSubTitle
                            )}
                        >
                            TSC
                        </Grid>
                    </Grid>
                ),
                columnHeader: s.isArchived ? (
                    <div>
                        <p className={classes.columnHeaderArchived}>{s.name}</p>
                        <p className={classes.columnHeaderArchivedText}>Archived</p>
                    </div>
                ) : (
                    <p>{s.name}</p>
                ),
                cellRenderer: (rowData) => {
                    const seatingPlanCategory = findSeatingPlanCategory(
                        s.id,
                        rowData.seatingPlanCategoryResultsSummaries
                    );
                    const sales = seatingPlanCategory?.sales || 0;
                    const tickets = seatingPlanCategory?.tickets || 0;
                    const salesCap = seatingPlanCategory?.salesCap || 0;

                    return (
                        <Grid container>
                            <Grid
                                item
                                xs={4}
                                className={classNames(
                                    classes.textAlignCenter,
                                    tickets === 0 && classes.zeroStatistic
                                )}
                            >
                                {tickets}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                className={classNames(
                                    classes.textAlignCenter,
                                    sales === 0 && classes.zeroStatistic
                                )}
                            >
                                {sales}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                className={classNames(
                                    classes.textAlignCenter,
                                    salesCap <= 0 && classes.zeroTicketSalesCap
                                )}
                            >
                                <a
                                    className={classNames(
                                        salesCap <= 0 && classes.zeroTicketSalesCap
                                    )}
                                    href={eventUrlService.details(rowData.id)}
                                >
                                    {salesCap}
                                </a>
                            </Grid>
                        </Grid>
                    );
                },
                colored: {
                    mode: 'normal',
                },
                width: TABLE_CELL_WIDTH.LARGE,
            }));
        }

        if (canLockEvent() || canUnlockEvent() || canCloseEvent()) {
            actionCols.push({
                title: 'Actions',
                cellRenderer: ({ id, status, dateTimeEnd, dateTimeStart, resultsSummary }) => {
                    const isEventPast = dateTimeEnd
                        ? moment.utc(dateTimeEnd).isBefore(new Date())
                        : moment.utc(dateTimeStart).isBefore(new Date());
                    const eventHasTicketsAndOrders =
                        resultsSummary &&
                        resultsSummary.ticketsInventoryQuantity > 0 &&
                        resultsSummary.ticketsSoldQuantity > 0;

                    const canClose = canCloseEvent() && status === 'Locked';
                    const canUnlock = canUnlockEvent() && status === 'Locked';
                    const canLock =
                        canLockEvent() &&
                        isEventPast &&
                        eventHasTicketsAndOrders &&
                        status !== 'Locked' &&
                        status !== 'Closed';

                    return (
                        <div className={classes.actionButtons}>
                            {canClose && (
                                <Button
                                    size={BUTTON_SIZES.TINY}
                                    onClick={() => onEventActionDispatched?.('close', id)}
                                >
                                    Close
                                </Button>
                            )}
                            {canUnlock && (
                                <Button
                                    size={BUTTON_SIZES.TINY}
                                    onClick={() => onEventActionDispatched?.('unlock', id)}
                                >
                                    Unlock
                                </Button>
                            )}
                            {canLock && (
                                <Button
                                    size={BUTTON_SIZES.TINY}
                                    onClick={() => onEventActionDispatched?.('lock', id)}
                                >
                                    Lock
                                </Button>
                            )}
                        </div>
                    );
                },
            });
        }

        cols = [
            {
                align: 'center',
                cellRenderer: (rowData) => {
                    switch (rowData.status) {
                        case 'Closed':
                            return (
                                <Tooltip title="Event Closed">
                                    <DoNotDisturbOnIcon color="error" />
                                </Tooltip>
                            );
                        case 'Locked':
                            return (
                                <Tooltip title="Event Locked">
                                    <LockIcon color="warning" />
                                </Tooltip>
                            );
                    }
                    return <></>;
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                title: 'Date',
                key: 'dateTimeStart',
                cellRenderer: (rowData) => moment.utc(rowData.dateTimeStart).format(dateFormat),
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                title: 'Event',
                key: 'name',
                cellRenderer: (rowData) => (
                    <a className={classes.eventLink} href={eventUrlService.details(rowData.id)}>
                        {rowData.name}
                    </a>
                ),
                width: TABLE_CELL_WIDTH.LARGE,
            },
            {
                title: 'Series',
                key: 'seriesName',
                cellRenderer: (rowData) => rowData.seriesName,
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                title: 'Tickets',
                key: 'resultsSummary',
                cellRenderer: (rowData) => {
                    const qty = rowData.resultsSummary?.ticketsInventoryQuantity || 0;

                    return (
                        <a
                            href={eventUrlService.tickets(rowData.id)}
                            className={classNames(
                                qty === 0 && classes.zeroStatistic,
                                classes.statistic
                            )}
                        >
                            {qty}
                        </a>
                    );
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                title: 'Sales',
                key: 'resultsSummary',
                cellRenderer: (rowData) => {
                    const qty = rowData.resultsSummary?.ticketsSoldQuantity || 0;

                    const renderedElement = (
                        <a
                            href={eventUrlService.orderlines(rowData.id)}
                            className={classNames(
                                qty === 0 && classes.zeroStatistic,
                                classes.statistic
                            )}
                        >
                            {qty}
                        </a>
                    );

                    if (qty === 0) {
                        return renderedElement;
                    }

                    return (
                        <Popover text={renderedElement} width={100}>
                            <>
                                <div>
                                    Sent: <strong>{rowData.resultsSummary?.ticketsSent}</strong>
                                </div>
                                <div>
                                    Not sent:{' '}
                                    <strong>{rowData.resultsSummary?.ticketsNotSent}</strong>
                                </div>
                            </>
                        </Popover>
                    );
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                title: 'Available',
                cellRenderer: (rowData) => {
                    const availableTickets = rowData.resultsSummary?.ticketsRemainingQuantity || 0;
                    return (
                        <span
                            className={classNames(
                                availableTickets === 0 && classes.zeroStatistic,
                                availableTickets < 0 && classes.negativeStatistic,
                                classes.statistic
                            )}
                        >
                            {availableTickets}
                        </span>
                    );
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
            ...cols,
            ...actionCols,
            {
                align: 'right',
                cellRenderer: (rowData) => {
                    return (
                        <IconButton
                            onClick={() => onClickDetails(rowData.id)}
                            color="primary"
                            size="small"
                        >
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    );
                },
                width: TABLE_CELL_WIDTH.TINY,
            },
        ];

        setColumns(cols);
    }, [
        includeResultsSummary,
        includeSeatingPlansFromSeatingPlanId,
        seatingPlanCategories,
        includeArchivedSeatingplanCategories,
        onEventActionDispatched,
    ]);

    return (
        <Table<Event>
            data={events}
            rowIdResolver={(rowData: Event) => ({ id: rowData.id })}
            columns={columns}
            onSortingChange={onChangeSorting}
            loading={loading}
            minWidth={650}
            pagination={pagination}
            enableCheckboxes={enableCheckboxes}
        />
    );
}
