import { useAccount } from '@azure/msal-react';
import { Roles } from 'src/data/enums/roles';
import { User } from 'src/data/models/user/user';

export function useUserProfile(): User {
    const account = useAccount();

    if (process.env.REACT_APP_DUMMY_DATA === 'true')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {
            email: 'dummy@email.com',
            name: 'Dummy User',
        };

    return {
        email: account?.username || '',
        name: account?.name || '',
        'msal-roles': (account?.idTokenClaims?.roles || []) as Roles[],
    };
}
