import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActivateEventDataWrapper } from 'src/app/components/data-wrappers/event/ActivateEventDataWrapper';
import { useDeactivateEventDataWrapper } from 'src/app/components/data-wrappers/event/DeactivateEventDataWrapper';
import { useDeleteEventDataWrapper } from 'src/app/components/data-wrappers/event/DeleteEventDataWrapper';
import { useUpdateEventDataWrapper } from 'src/app/components/data-wrappers/event/UpdateEventDataWrapper';
import { useUpdateEventNameDataWrapper } from 'src/app/components/data-wrappers/event/UpdateEventNameDataWrapper';
import { useUpdateEventOrganizerDataWrapper } from 'src/app/components/data-wrappers/event/UpdateEventOrganizerDataWrapper';
import { useUpdateEventSeriesDataWrapper } from 'src/app/components/data-wrappers/event/UpdateEventSeriesDataWrapper';
import CreateEventForm from 'src/app/components/forms/event/CreateEventForm';
import { CreateEventFormActionButtons } from 'src/app/components/forms/event/CreateEventFormActionButtons';
import { EditEventCategoryForm } from 'src/app/components/forms/event/EditEventCategoryForm';
import { EditEventNameForm } from 'src/app/components/forms/event/EditEventNameForm';
import { EditEventOrganizerForm } from 'src/app/components/forms/event/EditEventOrganizerForm';
import { EditEventSeriesForm } from 'src/app/components/forms/event/EditEventSeriesForm';
import useAssignEventCategory from 'src/app/hooks/event-categories/useAssignEventCategory';
import { useFetchEventById } from 'src/app/hooks/events/useFetchEventById';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import UserPermissions from 'src/app/utilities/helpers/userPermissions';
import UpdateEventDto from 'src/data/dtos/UpdateEventDto';
import { Divider } from 'src/view/components/divider/Divider';
import { CreateEventFormValues, useCreateEventForm } from '../../forms/event/useCreateEventForm';
import { PopUpForm } from '../../pop-up-form/pop-up-form';

interface EditEventFeatureProps {
    eventId: string;
    onUpdatedEvent?: () => void;
    onDeleteEvent?: () => void;
    onUpdatedName?: () => void;
    onUpdatedOrganizer?: () => void;
    onUpdatedSerie?: () => void;
    open: boolean;
    onClose: () => void;
    invalidatedAt?: number;
}

export const EditEventFeature = ({
    eventId,
    onUpdatedEvent,
    onUpdatedName,
    onUpdatedOrganizer,
    onUpdatedSerie,
    open,
    onClose,
    onDeleteEvent,
    invalidatedAt,
}: EditEventFeatureProps) => {
    const confirm = useCustomConfirm();
    const navigate = useNavigate();

    const { data: fetchEventData } = useFetchEventById(
        eventId,
        {},
        {
            enabled: !!eventId,
        }
    );
    
    const form = useCreateEventForm(fetchEventData?.data.data);

    const { updateEvent, loading: updateEventIsLoading } = useUpdateEventDataWrapper({
        eventId,
        onSuccess: onUpdatedEvent,
        invalidatedAt,
    });

    const { updateEventName, loading: updateEventNameIsLoading } = useUpdateEventNameDataWrapper({
        eventId,
        onSuccess: onUpdatedName,
        invalidatedAt,
    });

    const { updateEventOrganizer, loading: updateEventOrganizerIsLoading } =
        useUpdateEventOrganizerDataWrapper({
            eventId,
            onSuccess: onUpdatedOrganizer,
            invalidatedAt,
        });

    const { updateEventSeries, loading: updateEventSeriesIsLoading } =
        useUpdateEventSeriesDataWrapper({
            eventId,
            onSuccess: onUpdatedSerie,
            invalidatedAt,
        });

    const { mutateAsync: updateEventCategory, isLoading: isUpdatingEventCategory } =
        useAssignEventCategory();

    const {
        deleteEvent,
        loading: deleteEventIsLoading,
        errors: deleteEventErrors,
    } = useDeleteEventDataWrapper({
        onSuccess: () => {
            navigate('/');
            onDeleteEvent?.();
        },
    });

    const {
        deactivateEvent,
        loading: deactivateEventLoading,
        errors: deactivateEventErrors,
    } = useDeactivateEventDataWrapper({
        onSuccess: () => {
            onUpdatedEvent?.();
        },
    });

    const {
        activateEvent,
        loading: activateEventLoading,
        errors: activateEventErrors,
    } = useActivateEventDataWrapper({
        onSuccess: () => {
            onUpdatedEvent?.();
        },
    });

    const mapFormValuesToUpdateEventDto = (values: CreateEventFormValues): UpdateEventDto => {
        const {
            startDate,
            endDate,
            dateConfirmed,
            eventTags,
            description,
            homeTeam,
            awayTeam,
            allowAwayFans,
            requireFullCustomerAddress,
            blacklistedCountries,
            blacklistedNationalities,
        } = values;

        return {
            dateTimeStart: startDate ? moment.utc(startDate).format() : '',
            dateTimeEnd: endDate ? moment.utc(endDate).format() : '',
            dateConfirmed: dateConfirmed,
            tagIds: eventTags?.map((t) => t.value) || [],
            description: description || '',
            ticketsSentEarliestDays: values.ticketsSentEarliestDays?.toString() || '',
            ticketsSentLatestDays: values.ticketsSentLatestDays?.toString() || '',
            homeTeam: homeTeam?.value,
            awayTeam: awayTeam?.value,
            restrictions: {
                allowAwayFans,
                requireFullCustomerAddress,
                blacklistedCountryCodes: blacklistedCountries?.map((o) => o.value) || [],
                blacklistedNationalityCodes: blacklistedNationalities?.map((o) => o.value) || [],
            },
        };
    };

    const eventDefaultValues = useMemo(() => {
        if (!fetchEventData) return;

        const { organizerName, organizerId, seriesName, seriesId, eventCategory, eventCategoryId } =
            fetchEventData.data.data;

        return {
            organizer: {
                value: organizerId,
                label: organizerName,
            },
            series: {
                value: seriesId,
                label: seriesName,
            },
            category: {
                value: eventCategoryId,
                label: eventCategory,
            },
        };
    }, [fetchEventData]);
    
    return (
        <>
            {eventId && (
                <>
                    {fetchEventData && (
                        <PopUpForm
                            formState={form.formState}
                            open={open}
                            onClose={onClose}
                            title={`Edit ${fetchEventData?.data.data.name}`}
                        >
                            <>
                                <CreateEventForm
                                    form={form}
                                    eventDetails={fetchEventData.data.data}
                                    edit={true}
                                    actionLoading={updateEventIsLoading}
                                    onFormSubmit={(values) =>
                                        updateEvent(mapFormValuesToUpdateEventDto(values))
                                    }
                                />
                                <Divider />
                                <EditEventNameForm
                                    loading={updateEventNameIsLoading}
                                    canEditName={UserPermissions.canUpdateEventName()}
                                    values={{ name: fetchEventData?.data.data.name }}
                                    onSubmit={(values) => updateEventName(values.name)}
                                />
                                <Divider />
                                <EditEventOrganizerForm
                                    loading={updateEventOrganizerIsLoading}
                                    canEditOrganizer={UserPermissions.canUpdateEventOrganizer()}
                                    values={{ organizer: eventDefaultValues?.organizer }}
                                    onSubmit={(values) =>
                                        values.organizer &&
                                        updateEventOrganizer(values.organizer?.value)
                                    }
                                />
                                <Divider />
                                <EditEventSeriesForm
                                    loading={updateEventSeriesIsLoading}
                                    canEditSeries={UserPermissions.canUpdateEventSeries()}
                                    values={{ series: eventDefaultValues?.series }}
                                    onSubmit={(values) => {
                                        values.series && updateEventSeries(values.series.value);
                                    }}
                                />

                                <EditEventCategoryForm
                                    loading={isUpdatingEventCategory}
                                    canUpdateEventCategory={UserPermissions.canUpdateEventCategory()}
                                    values={{ category: eventDefaultValues?.category }}
                                    onSubmit={async (values) => {
                                        updateEventCategory({
                                            eventId,
                                            eventCategoryId: values.category?.value,
                                        }).then(() => onUpdatedEvent?.());
                                    }}
                                />
                                <Divider />
                                <CreateEventFormActionButtons
                                    eventIsActive={fetchEventData.data.data.isActive}
                                    onClickActivate={async () => {
                                        const result = await confirm({
                                            title: 'Are you sure you want to activate this event?',
                                            description:
                                                'You can still deactivate the event again after you confirm this.',
                                        });
                                        if (result) activateEvent(eventId);
                                    }}
                                    onClickDeactivate={async () => {
                                        const result = await confirm({
                                            title: 'Are you sure you want to deactivate this event?',
                                            description:
                                                'You can still active the event again after you confirm this.',
                                        });
                                        if (result) deactivateEvent(eventId);
                                    }}
                                    onClickDelete={async () => {
                                        const result = await confirm({
                                            title: 'Are you sure you want to delete this event?',
                                            description: 'Deleting an event will be permanent',
                                        });
                                        if (result) deleteEvent(eventId);
                                    }}
                                    activateIsLoading={activateEventLoading}
                                    deactivateIsLoading={deactivateEventLoading}
                                    deleteIsLoading={deleteEventIsLoading}
                                    activateErrors={activateEventErrors}
                                    deactivateErrors={deactivateEventErrors}
                                    deleteErrors={deleteEventErrors}
                                />
                            </>
                        </PopUpForm>
                    )}
                </>
            )}
        </>
    );
};
