import { useAccount, useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { UserRoleConfig, allowTo } from 'src/app/utilities/helpers/userRole';
import { Roles } from 'src/data/enums/roles';

export const useAllowedRoutes = () => {
    const isLoggedIn = useIsAuthenticated();

    const [allowedRoutes, setAllowedRoutes] = useState<UserRoleConfig | undefined>();
    const account = useAccount();

    useEffect(() => {
        if (!account) return;

        setAllowedRoutes(allowTo((account?.idTokenClaims?.roles || []) as Roles[]));
    }, [isLoggedIn, account]);

    return allowedRoutes;
};
